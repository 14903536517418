import { useReducer } from 'react';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';
import MainMenu from './MainMenu';
import VoiceApp from './VoiceApp';
import ResultsPage from './ResultsPage';

mixpanel.init('ec0a630172344b035fd22f8d8851a5fe');

let APP_MODES = {
	MAIN_MENU: 'MAIN_MENU',
	PLAYING: 'PLAYING',
	EXIT_SCREEN: 'EXIT_SCREEN',
};

let appModeReducerAndState = {
	reducer: function reducer(state, action) {
		if (!Object.values(APP_MODES).includes(action.payload.mode)) {
			throw new Error("Did not receive a valid app mode.");
		}
		return { mode: action.payload.mode };
	},
	initialState: function initialState() {
		return { mode: APP_MODES.MAIN_MENU };
	}
}

let LETTER_COUNT_REDUCER_ACTIONS = {
	DECREMENT: 'DECREMENT',
	SET: 'SET',
};

let letterCountReducerAndState = {
	reducer: function reducer(state, action) {
		switch (action.type) {
			case LETTER_COUNT_REDUCER_ACTIONS.DECREMENT:
				return { count: state.count - 1 };
			case LETTER_COUNT_REDUCER_ACTIONS.SET:
				return { count: action.payload.count };
			default:
				throw new Error("Did not receive a valid action.type for letterCount reducer.");
		}
	},
	initialState: function initialState() {
		return { count: 50 };
	}
};

let LETTER_ARRAY_REDUCER_ACTIONS = {
	APPEND: 'APPEND',
	CLEAR: 'CLEAR',
};

let letterArrayReducerAndState = {
	reducer: function reducer(state, action) {
		switch (action.type) {
			case LETTER_ARRAY_REDUCER_ACTIONS.APPEND:
				return {
					...state,
					letters: state.letters.concat(action.payload.letter)
				};
			case LETTER_ARRAY_REDUCER_ACTIONS.CLEAR:
				return { letters: [] };
			default:
				throw new Error("Did not receive a valid action.type for letterArray reducer.");
		}
	},
	initialState: function initialState() {
		return { letters: [] };
	}
};

function App() {
	const [appModeState, appModeDispatch] = useReducer(appModeReducerAndState.reducer, appModeReducerAndState.initialState());
	const [letterCountState, letterCountDispatch] = useReducer(letterCountReducerAndState.reducer, letterCountReducerAndState.initialState());
	const [lettersSeenState, lettersSeenDispatch] = useReducer(letterArrayReducerAndState.reducer, letterArrayReducerAndState.initialState());
	const [lettersMissedState, lettersMissedDispatch] = useReducer(letterArrayReducerAndState.reducer, letterArrayReducerAndState.initialState());

	console.log(appModeState);
	console.log(letterCountState);
	let currentPage = (<></>);
	switch (appModeState.mode) {
		case APP_MODES.MAIN_MENU:
			currentPage = (
				<>
					<MixpanelConsumer>
						{(mixpanel) =>
							<MainMenu
								submitLetterCount={(letterCount) => {
									letterCountDispatch({
										type: LETTER_COUNT_REDUCER_ACTIONS.SET,
										payload: { count: letterCount - 1 }
									});
									lettersSeenDispatch({
										type: LETTER_ARRAY_REDUCER_ACTIONS.CLEAR,
									});
									lettersMissedDispatch({
										type: LETTER_ARRAY_REDUCER_ACTIONS.CLEAR,
									});
									appModeDispatch({
										payload: { mode: APP_MODES.PLAYING }
									});
								}}
								mixpanel={mixpanel}
							/>
						}
					</MixpanelConsumer>
				</>
			);
			break;
		case APP_MODES.PLAYING:
			currentPage = (
				<>
					<MixpanelConsumer>
						{(mixpanel) =>
							<VoiceApp
								letterCount={letterCountState.count}
								addLetterSeen={(letter) => {
									lettersSeenDispatch({
										type: LETTER_ARRAY_REDUCER_ACTIONS.APPEND,
										payload: { letter: letter }
									});
								}}
								addLetterMissed={(letter) => {
									lettersMissedDispatch({
										type: LETTER_ARRAY_REDUCER_ACTIONS.APPEND,
										payload: { letter: letter }
									});
								}}
								decrementLetterCount={() => {
									letterCountDispatch({
										type: LETTER_COUNT_REDUCER_ACTIONS.DECREMENT,
									});
								}}
								exit={() => {
									appModeDispatch({
										payload: { mode: APP_MODES.EXIT_SCREEN }
									});
								}}
								mixpanel={mixpanel}
							/>
						}
					</MixpanelConsumer>
				</>
			);
			break;
		case APP_MODES.EXIT_SCREEN:
			currentPage = (
				<>
					<MixpanelConsumer>
						{(mixpanel) =>
							<ResultsPage
								lettersSeen={lettersSeenState.letters}
								lettersMissed={lettersMissedState.letters}
								backToMainMenu={() => {
									appModeDispatch({
										payload: { mode: APP_MODES.MAIN_MENU }
									});
								}}
								mixpanel={mixpanel}
							/>
						}
					</MixpanelConsumer>
				</>
			);
			break;
		default:
			throw new Error('Did not evaluate to a valid app mode.')
	}

	return (
		<MixpanelProvider mixpanel={mixpanel}>
			<div className="App">
				{currentPage}
			</div>
		</MixpanelProvider>
	);

}

export default App;