import { useState, useEffect } from 'react';
import { Button, SegmentedControl } from 'evergreen-ui';
import natoAssistLogo from './assets/nato-assist-logo.png';

import './MainMenu.css';

let TOTAL_LETTER_COUNTS = {
  TOTAL_25_MODE: 25,
  TOTAL_50_MODE: 50,
  TOTAL_75_MODE: 75,
  TOTAL_100_MODE: 100,
  TOTAL_INFINITE_MODE: -1,
};

function MainMenu({ submitLetterCount, mixpanel }) {
  var [segmentState, setSegementState] = useState(
    {
      options: [
        { label: TOTAL_LETTER_COUNTS.TOTAL_25_MODE, value: TOTAL_LETTER_COUNTS.TOTAL_25_MODE },
        { label: TOTAL_LETTER_COUNTS.TOTAL_50_MODE, value: TOTAL_LETTER_COUNTS.TOTAL_50_MODE },
        { label: TOTAL_LETTER_COUNTS.TOTAL_75_MODE, value: TOTAL_LETTER_COUNTS.TOTAL_75_MODE },
        { label: TOTAL_LETTER_COUNTS.TOTAL_100_MODE, value: TOTAL_LETTER_COUNTS.TOTAL_100_MODE },
        { label: "Infinite", value: TOTAL_LETTER_COUNTS.TOTAL_INFINITE_MODE },
      ],
      currentOption: TOTAL_LETTER_COUNTS.TOTAL_50_MODE,
    });

  // componentDidMount, ComponentDidUnmount
  useEffect(() => {
    mixpanel.track('Entered Main Page!');
    return function cleanup() {
      mixpanel.track('Exited Main Page!');
    }
  }, [mixpanel]);

  return (
    <div className="MainMenu">
      <header className="MainMenu-header">
        <img src={natoAssistLogo} alt={natoAssistLogo} />
        <h1>NATO Assist</h1>
      </header>
      <div className="MainMenu-content">
        <header className="MainMenu-sub-header">
          <h2>Use your voice to learn the NATO Phonetic Alphabet.</h2>
          <h2>Just talk to your device!</h2>
        </header>
        <div className="MainMenu-segment-container">
          <h2>Choose amount of letters:</h2>
          <SegmentedControl
            options={segmentState.options}
            value={segmentState.currentOption}
            onChange={value => setSegementState(
              {
                ...segmentState,
                currentOption: value,
              })}
            className="MainMenu-segment"
          />
        </div>
        <Button
          appearance="primary"
          className="MainMenu-button"
          onClick={() => {
            submitLetterCount(segmentState.currentOption);
          }}>Start</Button>
      </div>
    </div>
  );
}

export default MainMenu;
