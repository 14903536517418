import { useState, useEffect, useCallback } from 'react';
import { Button } from 'evergreen-ui';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { letterToSymbolMapping, symbolToLetterMapping, alphabet } from './LetterModule';

import './VoiceApp.css';

function getNewLetter(oldLetter) {
  let newLetter = oldLetter;
  while (newLetter === oldLetter) {
    let index = Math.floor(Math.random() * alphabet.length);
    newLetter = alphabet[index];
  }
  return newLetter;
}

function VoiceApp({ letterCount, addLetterSeen, addLetterMissed, decrementLetterCount, exit, mixpanel }) {
  var [currentLetter, setCurrentLetter] = useState('A');
  var [wordShown, setWordShown] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();

  const nextLetterCallback = useCallback(
    () => {
      addLetterSeen(currentLetter);
      setCurrentLetter(getNewLetter(currentLetter));
      mixpanel.track('New Letter', {'letter': currentLetter});
      if (letterCount > 0) {
        decrementLetterCount();
      }
      if (letterCount === 0) {
        exit();
      }
    },
    [addLetterSeen, setCurrentLetter, decrementLetterCount, exit, currentLetter, letterCount, mixpanel]
  );

  // componentDidMount, ComponentDidUnmount
  useEffect(() => {
    mixpanel.track('Entered Voice Page!');
    return function cleanup() {
      mixpanel.track('Exited Voice Page!');
    }
  }, [mixpanel]);

  useEffect(() => {
    SpeechRecognition.startListening({ continuous: true });
    console.log("Started Listening");
    return function cleanup() {
      SpeechRecognition.stopListening();
      console.log("Stopped Listening");
    };
  });

  useEffect(() => {
    if (transcript.length > 0) {
      var words = transcript.split(" ");
      if (words.length > 0 && !wordShown) {
        let lastWord = words[words.length - 1];
        if (symbolToLetterMapping[lastWord.toUpperCase()] === currentLetter) {
          nextLetterCallback();
        }
      }
      if (words.length > 10) {
        resetTranscript();
      }
    }
  }, [transcript, currentLetter, nextLetterCallback, resetTranscript, wordShown]);

  function onClick() {
    if (wordShown) {
      addLetterMissed(currentLetter);
      nextLetterCallback();
    }
    setWordShown(!wordShown);
  };

  function renderListeningNotification() {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      return (<h2 className="VoiceApp-listening-sign warning">Your Browser Does Not Support Voice Recognition!<br />Please Try Google Chrome.</h2>);
    }
    return (<h2 className="VoiceApp-listening-sign flashing">● Listening</h2>);
  }

  return (
    <div className="VoiceApp">
      <div className="VoiceApp-content">
        <div className="VoiceApp-topbar">
          <Button
            appearance="primary"
            intent="danger"
            className="VoiceApp-exit-button"
            onClick={exit}>Exit</Button>
          {renderListeningNotification()}
        </div>
        <header className="VoiceApp-header">
          <h1>{currentLetter}</h1>
          <h2 style={{ visibility: wordShown ? "visible" : "hidden" }}>{letterToSymbolMapping[currentLetter]}</h2>
        </header>
        <h2 className="VoiceApp-remaining">{"Remaining: " + (letterCount < 0 ? "∞" : letterCount.toString())}</h2>
        <Button
          appearance="primary"
          intent={wordShown ? "warning" : "danger"}
          className="VoiceApp-action-button"
          onClick={onClick}>{wordShown ? "Next Letter" : "I Don't Know"}</Button>
      </div>
      <div className="VoiceApp-transcript"><p>{'"' + transcript + '"'}</p></div>
    </div>
  );
}

export default VoiceApp;
