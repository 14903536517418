import { useEffect } from 'react';
import { Button, Table } from 'evergreen-ui';
import { letterToSymbolMapping } from './LetterModule';

import './ResultsPage.css';

function ResultsPage({ lettersSeen, lettersMissed, backToMainMenu, mixpanel }) {

  // componentDidMount, ComponentDidUnmount
  useEffect(() => {
    mixpanel.track('Entered Results Page!');
    return function cleanup() {
      mixpanel.track('Exited Results Page!');
    }
  }, [mixpanel]);

  function renderTable(letters) {
    return (
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>
            Letter
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>
            Symbol
          </Table.TextHeaderCell>
        </Table.Head>
        <Table.Body className="ResultsPage-results-table">
          {letters.map(letter => (
            <Table.Row key={letter}>
              <Table.TextCell>{letter}</Table.TextCell>
              <Table.TextCell>{letterToSymbolMapping[letter]}</Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  // let filteredAndSortedLettersSeen = [...new Set(lettersSeen)].sort();
  let filteredAndSortedLettersMissed = [...new Set(lettersMissed)].sort();
  // let fileredAndSortedLettersUnseen = [...alphabet]
  //   .filter(letter => !filteredAndSortedLettersSeen.includes(letter))
  //   .filter(letter => !filteredAndSortedLettersMissed.includes(letter))
  let correctAnswers = lettersSeen.length - lettersMissed.length;
  let totalAnswers = lettersSeen.length;
  mixpanel.track("Results", {"correct": correctAnswers, "total": totalAnswers});

  return (
    <div className="ResultsPage">
      <div className="ResultsPage-content">
        <header className="ResultsPage-header">
          <h1>{"Results (" + correctAnswers + "/" + totalAnswers + ")"}</h1>
        </header>
        <div className="ResultsPage-results-container">
          <h2>Missed Letters</h2>
          <h3>(Scroll Through)</h3>
          {renderTable(filteredAndSortedLettersMissed)}
        </div>
        <Button
          appearance="primary"
          className="ResultsPage-button"
          onClick={backToMainMenu}>Retry</Button>
      </div>
    </div>
  );
}

export default ResultsPage;
