
export let letterToSymbolMapping = {
  'A': 'ALFA',
  'B': 'BRAVO',
  'C': 'CHARLIE',
  'D': 'DELTA',
  'E': 'ECHO',
  'F': 'FOXTROT',
  'G': 'GOLF',
  'H': 'HOTEL',
  'I': 'INDIA',
  'J': 'JULIETT',
  'K': 'KILO',
  'L': 'LIMA',
  'M': 'MIKE',
  'N': 'NOVEMBER',
  'O': 'OSCAR',
  'P': 'PAPA',
  'Q': 'QUEBEC',
  'R': 'ROMEO',
  'S': 'SIERRA',
  'T': 'TANGO',
  'U': 'UNIFORM',
  'V': 'VICTOR',
  'W': 'WHISKEY',
  'X': 'X-RAY',
  'Y': 'YANKEE',
  'Z': 'ZULU',
};

export let symbolToLetterMapping = {
  'ALFA': 'A',
  'ALPHA': 'A',
  'BRAVO': 'B',
  'CHARLIE': 'C',
  'DELTA': 'D',
  'ECHO': 'E',
  'FOXTROT': 'F',
  'GOLF': 'G',
  'HOTEL': 'H',
  'INDIA': 'I',
  'JULIET': 'J',
  'JULIETT': 'J',
  'KILO': 'K',
  'LIMA': 'L',
  'MIKE': 'M',
  'NOVEMBER': 'N',
  'OSCAR': 'O',
  'PAPA': 'P',
  'QUEBEC': 'Q',
  'ROMEO': 'R',
  'SIERRA': 'S',
  'TANGO': 'T',
  'UNIFORM': 'U',
  'VICTOR': 'V',
  'WHISKEY': 'W',
  'X-RAY': 'X',
  'XRAY': 'X',
  'YANKEE': 'Y',
  'ZULU': 'Z',
};

export let alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];